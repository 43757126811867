<template>
  <div class="account">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
    <div class="account__header d-flex justify-content-between">
      <div>&nbsp;</div>
      <div
        class="account__header__items d-flex align-items-center justify-content-between"
      >
        <div class="account__logo">
          <!-- <LogoBlack />/ -->
        </div>
        <Country />
      </div>
    </div>
    <div class="account__container">
      <div class="account__left">
        <div class="account__left__bg"></div>
      </div>
      <div class="account__right d-flex flex-column">
        <div class="account__form__container vh-100">
          <div class="account__form__wrapper d-flex flex-column">
            <div class="account__head">
              <h5 class="account__head__title">Enable 2FA</h5>
              <p class="account__head__subtitle">
                Two Factor Authentication(2FA) was enabled for your
                organization, click on the enable button below to setup your
                2FA.
              </p>
            </div>
            <div class="account__body">
              <div class="account__form">
                <div
                  class="form__item form__action d-flex align-items-center justify-content-between"
                >
                  <div style="position: relative; z-index: 3">
                    <button
                      @click="goToDownloadApp"
                      class="button form__button form__button--lg"
                    >
                      {{ process ? "please wait..." : "Enable" }}
                    </button>
                    <img
                      style="
                        position: absolute;
                        left: -5rem;
                        top: -2.5rem;
                        z-index: -1;
                      "
                      src="/assets/img/dotted-bg.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="account__info mt-auto">
              <div class="account__info__container d-flex align-items-center">
                <div class="account__info__icon">
                  <img src="assets/img/help.svg" alt="" srcset="" />
                </div>
                <div class="">
                  <span class="account__text"
                    >Expert support available to you 24 /7.</span
                  >
                  <span class="account__text account__info__link text--capital">
                    Contact Support
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Country from "@/components/_base-country-svg.vue";
import { GET_TWO_FACTOR_AUTH } from "@/core/services/store/types";

export default {
  name: "Configure2Fa",

  data() {
    return {
      twoFaToken: "",
      resendTime: 0,
      isResend: false,
      process: false,
      intervalCount: null,
      confirmSend: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: `Welcome back`,
      },
    };
  },
  methods: {
    goToDownloadApp() {
      this.process = true;
      this.$store.dispatch(GET_TWO_FACTOR_AUTH).then(() => {
        this.process = false;
        this.$router.push({ name: "DownloadApp" });
      });
    },
  },

  mounted() {
    // this.resendCount();
  },

  components: {
    Country,
  },
};
</script>
